<template>
  <!-- 企业详细介绍 -->
  <div class="content">
    <img class="baseImg" :src="`http://public.half-half.cn/${baseImg}`" alt=""/>
    <div class="company">
      <span>公司介绍</span>
      <p>{{ qyxx.qyjs }}</p>
    </div>
    <el-divider></el-divider>
    <el-divider></el-divider>
    <div class="weui_body qyzl">
      <div class="weui_cell">
        <div class="weui_left">单位名称：</div>
        <div class="weui_right">{{ qyxx.qymc }}</div>
      </div>
      <div class="weui_cell">
        <div class="weui_left">联系电话：</div>
        <div class="weui_right">{{ qyxx.lxrdh }}</div>
      </div>
      <div class="weui_cell">
        <div class="weui_left">公司地址：</div>
        <div class="weui_right">{{ qyxx.addr }}</div>
      </div>
      <div class="weui_cell">
        <div class="weui_left">公司官网：</div>
        <div class="weui_right">{{ qyxx.url }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
  },
  computed: {
    qyxx() {
      return JSON.parse(this.$route.query.params);
    },
    baseImg() {
      return JSON.parse(this.qyxx.img);
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  padding: 6vw 3vw;
  padding-bottom: 10vh;
  // height: 100%;
}

.hd_title {
  font-size: 0.5rem;

  img {
    margin-right: 1vw;
  }
}

.baseImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.weui_body {
  .weui_cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #999999;
    margin: 3vw 0;
    font-size: 0.35rem;

    .weui_left {
      color: #0099ff;
    }
  }
}

.company {
  margin-bottom: 5vw;

  span {
    font-size: 0.5rem;
    font-weight: 900;
  }

  p {
    font-size: 0.35rem;
  }
}
</style>
