<template>
  <!-- 企业报告 -->
  <div class="content">
    <template v-if="baseImg && baseImg.length">
      <img v-for="img in baseImg" :key="img" class="baseImg" :src="`http://public.half-half.cn/${img}`" alt=""/>
    </template>
    <template v-else>
      <el-skeleton/>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    csgl() {
      return JSON.parse(this.$route.query.params);
    },
    baseImg() {
      return this.csgl.img ? JSON.parse(this.csgl.img) : [];
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  padding: 6vw 3vw;
  padding-bottom: 10vh;
  // height: 100%;
}

.hd_title {
  font-size: 0.5rem;

  img {
    margin-right: 1vw;
  }
}

.baseImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.weui_body {
  .weui_cell {
    display: flex;
    align-items: center;
    color: #999999;
    margin: 3vw 0;
    font-size: 0.3rem;
  }
}
</style>
