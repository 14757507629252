<template>
  <!-- 基地信息 -->
  <div class="content">
    <div class="hd_title">
      <img src="@/assets/H5/jdxx.png" alt=""/>
      <span>种植基地信息</span>
      <div class="weui_body">
        <div class="weui_cell">
          <div class="weui_left">种植基地名称：</div>
          <div class="weui_right">{{ jdxx.baseName }}</div>
        </div>
        <div class="weui_cell">
          <div class="weui_left">种植基地地址：</div>
          <div class="weui_right">{{ jdxx.baseAddress }}</div>
        </div>
        <div class="weui_cell">
          <div class="weui_left">坐标：</div>
          <div class="weui_right">{{ jdxx.centerPos }}</div>
        </div>
        <img v-for="img in baseImg" :key="img" class="baseImg" :src="`http://public.half-half.cn/${img}`" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
  },
  computed: {
    jdxx() {
      return JSON.parse(this.$route.query.params);
    },
    baseImg() {
      return JSON.parse(this.jdxx.baseImg);
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  padding: 6vw 3vw;
  padding-bottom: 10vh;
  // height: 100%;
}

.hd_title {
  font-size: 0.5rem;

  img {
    margin-right: 1vw;
  }
}

.baseImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.weui_body {
  .weui_cell {
    display: flex;
    align-items: center;
    color: #999999;
    margin: 3vw 0;
    font-size: 0.3rem;
  }
}
</style>
