<template>
  <div class="content">
    <el-header>
      <div class="page_title">{{ $route.query.name }}</div>
    </el-header>
    <el-main>
      <div class="maindiv">
        <!-- 检测报告 -->
        <jcbg v-if="$route.query.bs == 'jcbg'"></jcbg>
        <!-- 溯源信息 -->
        <syxx v-if="$route.query.type == 'syxx'"></syxx>
        <!-- 基地信息 -->
        <jdxx v-if="$route.query.type == 'jdxx'"></jdxx>
        <!-- 企业资质 -->
        <qyzz v-if="$route.query.bs == 'qyzz'"></qyzz>
        <!-- 企业详细介绍 -->
        <qyxxjs v-if="$route.query.bs == 'qyjs'"></qyxxjs>
      </div>
    </el-main>
    <!-- <el-footer> -->
    <div class="page_footer" @click="$router.go(-1)">返回首页</div>
    <!-- </el-footer> -->
  </div>
</template>

<script>
import jcbg from './component/jcbg.vue';
import syxx from './component/syxx.vue';
import jdxx from './component/jdxx.vue';
import qyzz from './component/qyzz.vue';
import qyxxjs from './component/qyxxjs.vue';

export default {
  components: {jcbg, syxx, jdxx, qyzz, qyxxjs}
};
</script>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}

.content {
  height: 100%;
  overflow-y: scroll;
}

.page_title {
  width: 100vw;
  background-color: green;
  color: #fff;
  font-size: 0.5rem;
  text-align: center;
  padding: 4vw 0;
}

.page_footer {
  width: 100vw;
  // height: 100%;
  background-color: green;
  color: #fff;
  text-align: center;
  font-size: 0.4rem;
  padding: 3vw 0;
}
</style>
