<template>
  <!-- 企业资质 -->
  <div class="content">
    <img v-for="img in baseImg" :key="img" class="baseImg" :src="`http://public.half-half.cn/${img}`" alt=""/>
  </div>
</template>

<script>
export default {
  created() {
  },
  computed: {
    qyxx() {
      return JSON.parse(this.$route.query.params);
    },
    baseImg() {
      return JSON.parse(this.qyxx.zzzs);
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  padding: 6vw 3vw;
  padding-bottom: 10vh;
  // height: 100%;
}

.hd_title {
  font-size: 0.5rem;

  img {
    margin-right: 1vw;
  }
}

.baseImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.weui_body {
  .weui_cell {
    display: flex;
    align-items: center;
    color: #999999;
    margin: 3vw 0;
    font-size: 0.3rem;
  }
}
</style>
