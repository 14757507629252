<template>
  <div class="content">
    <template v-if="pageData && pageData.length">
      <div class="timeline">
        <div class="timeline-item" v-for="item in pageData" :key="item.id">
          <div class="timeline-dot"></div>
          <div class="timeline-content">
            <div class="justify-between">
              <h3 style="color: #444443; margin-bottom: 2vw">作业信息</h3>
              <time>{{ item.zxwcsj }}</time>
            </div>
            <h3>任务名称</h3>
            <div class="weui_body">
              <div class="weui_cell">
                <div class="weui_left">负责人：</div>
                <div class="weui_right">{{ item.zxr }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">电话：</div>
                <div class="weui_right">{{ item.zxrdh }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">使用数量：</div>
                <div class="weui_right">{{ item.sysl }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">作业内容：</div>
                <div class="weui_right">{{ item.taskDesc }}</div>
              </div>
              <div class="weui_cell">
                <img :key="img" class="baseImg" :src="`http://public.half-half.cn/${item.taskImg}`" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Add more timeline items as needed -->
    <template v-else>
      <el-skeleton/>
    </template>
  </div>
</template>

<script>
import {getPage} from '@/request/api/h5.js';
export default {
  data() {
    return {
      pageData: []
    };
  },
  computed: {
    csgl() {
      return JSON.parse(this.$route.query.params);
    },
    cpid() {
      return JSON.parse(this.csgl.cpid);
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getPage('/pro10/nsrw/listTask?cropId=' + this.cpid).then((res) => {
        if (res.status == 200) {
          if (res.data.state == 'success') {
            this.pageData = res.data.data;
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.timeline {
  display: flex;
  flex-direction: column;
  padding: 10vh 5vw; /* Padding in viewport units */
  box-sizing: border-box;
}

.timeline-item {
  display: flex;
  align-items: center;
  margin-bottom: 5vh; /* Margin in viewport units */
}

.timeline-dot {
  width: 4vw; /* Width in viewport units */
  height: 4vw; /* Height in viewport units */
  background-color: #ffd700;
  border-radius: 50%;
  margin-right: 5vw; /* Margin in viewport units */
  flex-shrink: 0;
}

.timeline-content {
  flex-grow: 1;
  padding: 2vh 0; /* Padding in viewport units */
}

.timeline-content h2 {
  margin: 0;
  font-size: 4vw; /* Font size in viewport units */
}

.timeline-content h3 {
  margin: 0;
  font-size: 4vw; /* Font size in viewport units */
}

.timeline-content p {
  margin: 0.5vh 0; /* Margin in viewport units */
  font-size: 3vw; /* Font size in viewport units */
  color: #555;
}

.timeline-content time {
  display: block;
  font-size: 2.5vw; /* Font size in viewport units */
  color: #888;
}

@media (min-width: 375px) {
  /* Adjustments for larger screens, if necessary */
  .timeline-content h2 {
    font-size: 18px; /* Fixed size for larger screens */
  }

  .timeline-content p {
    font-size: 14px; /* Fixed size for larger screens */
  }

  .timeline-content time {
    font-size: 12px; /* Fixed size for larger screens */
  }
}

.baseImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.weui_body {
  font-size: 4vw;

  .weui_cell {
    display: flex;
    align-items: center;
    color: #444443;
    margin: 1vw 0;
  }
}

.justify-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/deep/ .el-carousel__arrow--left {
  left: 4vw !important;
}

/deep/ .el-carousel__arrow--right {
  right: 4vw !important;
}

/deep/ .el-carousel__arrow {
  width: 10vw;
  height: 10vw;
}
</style>
